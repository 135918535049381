<template>
    <div class="topics-search">
        <topics-list :list-endpoint="listEndpoint" />
    </div>
</template>

<script>
export default {
    name: "TopicsSearch",
    components: {
        TopicsList: () => import(/* webpackChunkName: "topics-list" */ "@/components/organisms/topics-list.vue")
    },
    props: {
        listEndpoint: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.tags-search {

}
</style>
